import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, useParams, NavLink, Link } from 'react-router-dom';
import './App.css';

const Header = () => <header>
    <ul>
        <li><NavLink to="/">Home</NavLink></li>
        <li><NavLink to="/clients">Clients</NavLink></li>
        <li><NavLink to="/projects">Projects</NavLink></li>
        <li><NavLink to="/dsadsdasd">404</NavLink></li>
    </ul>
</header>;
const Footer = () => <footer>footer</footer>;

interface ClientType {
    client_id: string;
    client_name: string;
}

interface ClientsType {
    clients: ClientType[];
}

interface ProjectType {
    site_id: string;
    site_client: string;
    site_url: string;
}

interface ProjectTypes {
    projects: ProjectType[];
}

export default class App extends Component {
    state = {
        clients: [],
        projects: []
    };

    componentDidMount() {
        if(!this.state.clients.length) {
            fetch('https://2021.apagecode.com/feed.json')
                .then(res => res.json())
                .then((data) => {
                    this.setState({ clients: data.clients, projects: data.projects })
                })
                .catch(console.log)
        }
    }

    render() {
        const Home = () => <>Home</>;
        const Clients = () => <><h1>Clients</h1> <ClientsListView clients={this.state.clients} /></>;
        const Client = () => <><h1>Client</h1> <ClientView clients={this.state.clients} /></>;
        const Projects = () => <><h1>Projects</h1> <ProjectsListView projects={this.state.projects} /></>;
        const Project = () => <><h1>Project</h1> <ProjectView projects={this.state.projects} /></>;
        const Error = () => <>Oops</>;

        return (
            <Router>
                <Header />
                <main>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact path="/clients" component={Clients}/>
                        <Route path="/clients/:clientID" component={Client}/>
                        <Route exact path="/projects" component={Projects}/>
                        <Route path="/projects/:projectID" component={Project}/>
                        <Route path="/*" component={Error}/>
                    </Switch>
                </main>
                <Footer />
            </Router>
        )
    }
}

const ClientsListView = ({clients} : ClientsType) => {
    try{
        return (
            <div>
                {clients.map((client: ClientType) => (
                    <div className="card" key={client.client_id}>
                        <div className="card-body">
                            <Link to={`/clients/${client.client_id}`}><h5 className="card-title">{client.client_name}</h5></Link>
                        </div>
                    </div>
                ))}
            </div>
        )
    } catch(e){
        return (
            <>{JSON.stringify(clients)}</>
        );
    }
};

const ClientView = ({clients} : ClientsType) => {
    const { clientID } = useParams<{ clientID: string }>();
    const client = clients.filter(obj => {
        return obj.client_id === clientID
    });
    return (
        <>{JSON.stringify(client)}</>
    )
}

const ProjectsListView = ({projects} : ProjectTypes) => {
    try{
        return (
            <div>
                {projects.map((project: ProjectType) => (
                    <div className="card" key={project.site_id}>
                        <div className="card-body">
                            <Link to={`/projects/${project.site_url}`}><h5 className="card-title">{project.site_client}</h5></Link>
                        </div>
                    </div>
                ))}
            </div>
        )
    } catch(e){
        return (
            <>{JSON.stringify(projects)}</>
        );
    }
};

const ProjectView = ({projects} : ProjectTypes) => {
    const { projectID } = useParams<{ projectID: string }>();
    const project = projects.filter(obj => {
        return obj.site_url === projectID
    });
    return (
        <>{JSON.stringify(project)}</>
    )
}
